import { useEffect, useRef, useState } from 'react'
import type { IPicture } from '../../../types/pictures'

import { Picture } from '../../molecules/Picture'
import { SVGIcon } from '../SVGIcon'
import { theme } from '../../../styles'
import { durationToTimer } from '../../../helpers/durationToTimer'
import { useIntersection } from '../../../hook/useIntersection'

export interface IPreviewVideo {
  url: string
  pictures: IPicture
  duration: number | string
  triggerType?: string
  isVideoVertical?: boolean
}

export function PreviewVideo({
  url,
  pictures,
  duration,
  triggerType = 'hover',
  isVideoVertical,
}: IPreviewVideo): JSX.Element {
  const [previewEnabled, setPreviewEnabled] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const itemRef = useRef<HTMLDivElement>(null)
  const { inView } = useIntersection({
    ref: triggerType === 'scroll' ? itemRef.current : null,
    onEnter: () => {
      if (triggerType === 'scroll') setPreviewEnabled(true)
    },
    onLeave: () => {
      if (triggerType === 'scroll') setPreviewEnabled(false)
    },
    options: {
      threshold: 1,
    },
  })

  // making sure scroll observer use the ref we want
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const isPreviewOn = previewEnabled && inView && url && isLoaded

  return (
    <>
      <div className="Preview__Video" ref={itemRef}>
        <Picture {...pictures} />
        <div className="Preview__Video__Player__Wrapper">
          {isPreviewOn ? (
            <video
              className="Preview__Video__Player"
              src={url}
              controls={false}
              playsInline
              autoPlay
              muted
              loop
            ></video>
          ) : null}
        </div>
      </div>
      <div className="Preview__Video__Icon">
        <SVGIcon name="play-circle" secondaryColor={theme.cssVars.white} size="37px" />
      </div>
      {duration && Number(duration) > 0 ? (
        <div className="Preview__Video__Duration">{durationToTimer(Number(duration))}</div>
      ) : null}

      <style jsx>{`
        .Preview__Video :global(figure) {
          padding-top: ${isVideoVertical ? '177.77%' : '56.25%'};
        }
      `}</style>
      <style jsx>{`
        .Preview__Video__Player__Wrapper {
          opacity: ${isPreviewOn ? '1' : '0'};
        }
      `}</style>

      <style jsx>{`
        .Preview__Video,
        .Preview__Video :global(figure) {
          display: block;
          width: 100%;
        }

        .Preview__Video :global(picture),
        .Preview__Video :global(img) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .Preview__Video :global(.PictureElementList__PlaceHolder) {
          z-index: 0;
        }

        .Preview__Video__Player__Wrapper {
          transition: opacity 0.5s ease 0s;
        }

        .Preview__Video__Player {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .Preview__Video__Player__Filter {
          background-color: ${theme.cssVars.deepBlue};
          opacity: 0.5;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }

        .Preview__Video__Icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .Preview__Video__Duration {
          position: absolute;
          bottom: 30px;
          left: 25px;
          background-color: ${theme.cssVars.white};
          padding: 3px 6px 0;
          border-radius: 50px;
          font-size: 13px;
          font-weight: 600;
          line-height: 145%;
        }
      `}</style>
    </>
  )
}
